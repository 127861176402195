var moment = require('moment');

export const getUser = () => {
  const userStr = sessionStorage.getItem('user');
  if (userStr) return JSON.parse(userStr);
  else return null;
}

export const getToken = () => {
  return sessionStorage.getItem('token') || null;
}

export const removeUserSession = () => {
  sessionStorage.removeItem('token');
  sessionStorage.removeItem('user');
}

export const setUserSession = (token, user) => {
  sessionStorage.setItem('token', token);
  sessionStorage.setItem('user', JSON.stringify(user));
}

export function createColumns(week) {
  const monday = moment.utc().week(week).startOf('week');
  var columns = [
    { name: 'id', title: 'Security' },
  ];
  for (let i = 1; i <= 5; i++) {
    const day = monday.clone().day(i);
    const name = day.valueOf();
    columns.push({ name: name, title: day.format("ddd D") })
  }
  return columns
}

export function createRows(data) {
  var rows = [];
  let dataCopy = JSON.parse(JSON.stringify(data));
  for (var [security, value] of Object.entries(dataCopy)) {
    value['id'] = security;
    rows.push(value)
  }
  return rows;
}
