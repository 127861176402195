import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import {RankingTable} from './RankingTable.js';
import { getToken } from './Utils/Common';
import axios from 'axios';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export function RankingTabs(props) {
  const [value, setValue] = useState(Number(localStorage.getItem('rankingTabValue')));
  const [tabList, setTabList] = useState([]);

  useEffect(() => {
    const token = getToken();
    if (!token) {
      return;
    }
    let url = new URL(process.env.REACT_APP_API_URL);
    url.pathname += '/sector-ranking-config';

    axios.get(
      url,
      {
        auth: {
          username: token,
          password: 'unused',
        }
      },
    )
    .then((response) => {
      setTabList(response.data);
    })
    .catch((error) => console.error('Error fetching data:', error));
}, []);

  const handleChange = (event, newValue) => {
    localStorage.setItem('rankingTabValue', newValue);
    setValue(newValue);
  };

  return (
    <div sx={{ flexGrow: 1, bgcolor: 'text.secondary'}}>
      <Paper square>
        <Tabs value={value} onChange={handleChange} aria-label="ranking-tabs">
          {tabList.map((tab, index) => (
            <Tab key={index} label={tab} {...a11yProps(index)} />
          ))}
        </Tabs>
      </Paper>
      {tabList.map((tab, index) => (
        <TabPanel key={index} value={value} index={index}>
          <RankingTable list={tab} />
        </TabPanel>
      ))}
    </div>
  );
}

export default RankingTabs;
