import React, {useState, useRef, useCallback, useEffect} from 'react';
import axios from 'axios';
import saveAs from 'file-saver';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import * as PropTypes from 'prop-types';
import Input from '@mui/material/Input';

import {getToken, removeUserSession} from './Utils/Common';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import MenuItem from '@mui/material/MenuItem';
import CloseIcon from '@mui/icons-material/Close';
import {
  Grid,
  Table,
  TableFixedColumns,
  TableHeaderRow,
  TableSelection,
  TableFilterRow,
  PagingPanel,
  SearchPanel,
  Toolbar,
  DragDropProvider,
  ColumnChooser,
  TableColumnVisibility,
  ExportPanel,
  TableGroupRow,
  GroupingPanel,
  TableEditRow,
} from '@devexpress/dx-react-grid-material-ui';
import {
  SelectionState,
  PagingState,
  IntegratedPaging,
  IntegratedSelection,
  SortingState,
  IntegratedSorting,
  SearchState,
  IntegratedFiltering,
  FilteringState,
  GroupingState,
  IntegratedGrouping,
  DataTypeProvider,
  EditingState,
} from '@devexpress/dx-react-grid';

import {styled} from '@mui/system';
import LinearProgress from '@mui/material/LinearProgress';
import {
  Plugin,
  Template,
  TemplatePlaceholder,
} from '@devexpress/dx-react-core';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import {
  tableColumnExtensionsSettings,
  hiddenColumnsSettings,
  integerColumnsSettings,
  floatColumnsSettings,
  filterColumnsSettings,
  stringColumnsSettings,
} from './Settings.js';


const pluginDependencies = [
  { name: 'Toolbar' }
];

const StyledLinearProgress = styled(LinearProgress)({
  width: '10%',
  '& > * + *': {
    marginTop: theme => theme.spacing(2),
  },
});

function LinearIndeterminate() {
  return (
    <StyledLinearProgress />
  );
}

function NoData() {
  return (
    <td>Loading...</td>
  );
}

export const FilterSelector = (props) => {
  const [poolingItem, setPoolingItem] =  useState('');
  const { columnName, items } = props;

  const handleChange = (event) => {
    setPoolingItem(event.target.value);
    props.onChange(columnName, event.target.value);
  };

  const handleClick = (event) => {
    setPoolingItem('');
    props.onClick(columnName);
  };

  return (
    <div key={columnName}>
      <InputLabel htmlFor="filter-field">
        {columnName}
      </InputLabel>
      <Select onChange={handleChange} value={poolingItem}>
        {items.map(item => {
          if (item === null) {
            return null;
          }
          return <MenuItem key={item} value={item}>{item}</MenuItem>;
        })}     
      </Select>
      <Button
        onClick={(e) => handleClick(e)}
        key={columnName}
        value={columnName}
      >
        Clear
      </Button>
    </div>
  );
};

const FloatEditor = ({ value, onValueChange }) => {
  const handleChange = (event) => {
    const { value: targetValue } = event.target;
    if (targetValue.trim() === '') {
      onValueChange();
      return;
    }
    onValueChange(parseFloat(targetValue));
  };
  return (
    <Input
      type="number"
      fullWidth
      value={value === undefined ? '' : value}
      inputProps={{
        min: 0,
        placeholder: '...',
      }}
      onChange={handleChange}
    />
  );
};


FloatEditor.propTypes = {
  value: PropTypes.number,
  onValueChange: PropTypes.func.isRequired,
};

FloatEditor.defaultProps = {
  value: undefined,
};

const IntegerEditor = ({ value, onValueChange }) => {
  const handleChange = (event) => {
    const { value: targetValue } = event.target;
    if (targetValue.trim() === '') {
      onValueChange();
      return;
    }
    onValueChange(parseInt(targetValue, 10));
  };
  return (
    <Input
      type="number"
      fullWidth
      value={value === undefined ? '' : value}
      inputProps={{
        min: 0,
        placeholder: '...',
      }}
      onChange={handleChange}
    />
  );
};

IntegerEditor.propTypes = {
  value: PropTypes.number,
  onValueChange: PropTypes.func.isRequired,
};

IntegerEditor.defaultProps = {
  value: undefined,
};

const StringEditor = ({ value, onValueChange }) => {

  const handleChange = (event) => {

    const { value: targetValue } = event.target;

    if (targetValue.trim() === '') {
      onValueChange();
      return;
    }

    onValueChange(targetValue);
  };

  return (
    <Input
      type="text"
      fullWidth
      value={value === undefined ? '' : value}
      inputProps={{
        min: 0,
        placeholder: '...',
      }}
      onChange={handleChange}
    />
  );
};

StringEditor.propTypes = {
  onValueChange: PropTypes.func.isRequired,
};

StringEditor.defaultProps = {
  value: undefined,
};

export const RequestButton = (props) => {

  const handleClick = () => {
    props.onChange();
  };

  return (
    <Plugin
      name="ToolbarUrlParameter"
      dependencies={pluginDependencies}
    >
      <Template name="toolbarContent">
        <TemplatePlaceholder />
        <Button onClick={handleClick} disabled={props.disabled}>
          {props.label}
        </Button>
      </Template>
    </Plugin>
  );
}


export const ToolbarFilters = (props) => {
  const [filters, setFilters] = useState({});

  const handleChange = (columnName, value) => {
    const fMap = {
      ...filters,
      [columnName]: {
        columnName: columnName,
        value: value,
        operation: 'equal',
      }
    }
    props.onFilterChange(Object.values(fMap));
    setFilters(fMap);
  };

  const handleClick = (columnName) => {
    const fMap = filters;
    delete fMap[columnName];
    props.onFilterChange(Object.values(fMap));
    setFilters(fMap);
  };

  return (
    <Plugin
      name="ToolbarFilter"
      dependencies={pluginDependencies}
    >
      <Template name="toolbarContent">
        <TemplatePlaceholder />
        {
          props.columnNames.map(columnName => {
            return <FilterSelector
              key={columnName}
              columnName={columnName}
              items={Array.from(props.filterColumnsDict[columnName])}
              onChange={handleChange}
              onClick={handleClick}
            />
          })
        }
      </Template>
    </Plugin>
  );
}


export const Loading = () => (
  <div className="loading-shading-mui">
    <LinearProgress className="loading-icon-mui" />
  </div>
);


const CellComponent = (props) => {
  const { column, row } = props;

  if (column.name.startsWith('change')) {
    const cellValue = row[column.name];
    const cellColor = cellValue < -1 ? 'red' : cellValue > 1 ? 'green' : 'inherit';

    return (
      <Table.Cell
        {...props}
        style={{
          padding: '1px',
          fontSize: '10px',
          width: '10%',
          color: cellColor,
        }}
      />
    );
  }

  return (
    <Table.Cell
      {...props}
      style={{
        padding: '1px',
        fontSize: '10px',
        width: '10%',
      }}
    />
  );
};


const HeaderCellComponent = (props) => (
  <TableHeaderRow.Cell
    {...props}
    style={{
      fontSize: '8px',
      wordWrap: "break-word",
      whiteSpace: "normal"
    }}
  />
);


function createColumns(data, indicatorType) {
  const dataCopy = JSON.parse(JSON.stringify(data));
  const columns = [];

  for (const name of Object.keys(dataCopy)) {
    columns.push({name: name, title: name});
  }
  return columns
}


function createRows(data) {
  var rows = [];
  const dataCopy = JSON.parse(JSON.stringify(data));

  for (const [name, values] of Object.entries(dataCopy)) {

    for (const [i, value] of Object.values(values).entries()) {

      if (!rows[i]) {
        rows[i] = Object()
      }
      rows[i][name] = value
    }
  }
  return rows;
}


const onSave = (workbook) => {
  workbook.xlsx.writeBuffer().then((buffer) => {
    saveAs(
      new Blob([buffer],{ type: 'application/octet-stream' }),
      'OptimizationResults.xlsx'
    );
  });
};


function createFilterColumnDict(rows, filterColumns) {
  let dict = {};
  for (const row of Object.values(rows)) {
    for (const columnName of Object.values(filterColumns)) {

      if (!(columnName in dict)) {
        dict[columnName] = new Set();
      }
      dict[columnName].add(row[columnName]);
    }
  }
  return dict;
}


export const TableBase = (props) => {
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [numericFilterOperations] = useState([
    'greaterThan',
    'equal',
    'notEqual',
    'greaterThanOrEqual',
    'lessThan',
    'lessThanOrEqual',
  ]);
  const [stringFilterOperations] = useState([
    'contains',
    'notContains',
    'startsWith',
    'endsWith',
    'equal',
    'notEqual'
  ]);


  const exporterRef = useRef(null);

  const startExport = useCallback((options) => {
    exporterRef.current.exportGrid(options);
  },  [exporterRef]);

  const [grouping, setGrouping] = useState([]);
  const [integerColumns] = useState(integerColumnsSettings);
  const [floatColumns] = useState(floatColumnsSettings);
  const [filterColumns] = useState(filterColumnsSettings);

  const [stringColumns] = useState(stringColumnsSettings);

  const [filterColumnsDict, setFilterColumnsDict] = useState();
  const [filters, setFilters]  = useState([]);
  const [toolbarFilters, setToolbarFilters]  = useState([]);
  const [selection, setSelection] = useState([]);

  const [hiddenColumnNames, setHiddenColumnNames] = useState(
    hiddenColumnsSettings
  );

  const [visibleColumns, setVisibleColumns] = useState();

  const [tableColumnExtensions] = useState(
    tableColumnExtensionsSettings
  );

  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState();

  const [leftColumns] = useState(
    [
      TableSelection.COLUMN_TYPE,
    ]
  );

  useEffect(() => {
    const token = getToken();
    if (!token) {
      return;
    }
    let url = new URL(process.env.REACT_APP_API_URL);
    url.pathname += props.dataEndpoint;
    axios.get(
      url,
      {
        auth: {
          username: token,
          password: 'unused',
        }
      },
    )

      .then((response) => {
        const columns = createColumns(response.data);
        const rows = createRows(response.data);
        const hiddenColumns = hiddenColumnNames.map(
          k => ({name: k, title: k})
        );
        const visibleColumns = columns.filter(
          a => !hiddenColumns.map(b=>b.name).includes(a.name)
        );

        setColumns(columns);
        setVisibleColumns(visibleColumns);
        setRows(rows);
        setFilterColumnsDict(createFilterColumnDict(rows, filterColumns));
        setLoading(false);

      }).catch((error) => {
        removeUserSession();
        props.history.push('/login');
      });
  }, [filterColumns, hiddenColumnNames, props.history, props.dataEndpoint]);


  if (loading) {
    return (
      <LinearIndeterminate />
    );
  };

  const handleToolbarFilterChange = (filter) => {
    setToolbarFilters(filter);
  };

  const handleFiltersChange = (event) => {
    let set = new Set();
    toolbarFilters.forEach(item => set.add(item));
    event.forEach(item => set.add(item));
    setFilters(Array.from(set));
  };

  const handleHiddenColumnNamesChange = (hiddenColumnNames) => {
    const hiddenColumns = hiddenColumnNames.map(k => ({name: k, title: k}))
    const visibleColumns = columns.filter(
      a => !hiddenColumns.map(b=>b.name).includes(a.name)
    );
    setHiddenColumnNames(hiddenColumnNames);
    setVisibleColumns(visibleColumns);
  };

  const handleAddRequest = () => {
    var selectedRows = selection.map(i => rows[i]);

    const token = getToken();
    if (!token) {
      return;
    }
    let url = new URL(process.env.REACT_APP_API_URL);
    url.pathname += props.addRequestEndpoint;

    axios.post(url, {
      selected_rows: selectedRows,
    }, {
      auth: {
        username: token,
        password: 'unused',
      },
    },
    )
      .then((response) => {
        setSnackBarMessage(response.data);
        setSnackBarOpen(true);

      }).catch((error) => {
        removeUserSession();
        props.history.push('/login');
      });
  };

  const handleDeleteRequest = () => {
    var selectedRows = selection.map(i => rows[i]);


    const token = getToken();
    if (!token) {
      return;
    }
    let url = new URL(process.env.REACT_APP_API_URL);

    url.pathname += props.deleteRequestEndpoint;


    axios.post(url, {
      selected_rows: selectedRows,
    }, {
      auth: {
        username: token,
        password: 'unused',
      },
    },

    )
      .then((response) => {
        setSnackBarMessage(response.data);
        setSnackBarOpen(true);
        axios.get(
          url,
          {
            auth: {
              username: token,
              password: 'unused',
            }
          },
        )

          .then((response) => {

            const columns = createColumns(response.data);
            const rows = createRows(response.data);
            const hiddenColumns = hiddenColumnNames.map(
              k => ({name: k, title: k})
            );
            const visibleColumns = columns.filter(
              a => !hiddenColumns.map(b=>b.name).includes(a.name)
            );

            setColumns(columns);
            setVisibleColumns(visibleColumns);
            setRows(rows);
            setFilterColumnsDict(createFilterColumnDict(rows, filterColumns));
            setLoading(false);
            setSelection([]);
          }).catch((error) => {
            removeUserSession();
            props.history.push('/login');
          });

      }).catch((error) => {
        removeUserSession();
        props.history.push('/login');
      });

    url = new URL(process.env.REACT_APP_API_URL);
    url.pathname += props.poolingEndpoint;

  };

  const handleSnackBarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackBarOpen(false);
  }

  const makeSnackBarMessage = (message) => {
    if (message) {
      if (message.deleted_items) {
        return (
          <div>
            Deleted<br/>
            <ul>
              {message.deleted_items.map(s => <li key={s}>{s}</li>)}
            </ul>
          </div>
        );
      }
      if (message.added_items) {
        return (
          <div>
            Added<br/>
            <ul>
              {message.added_items.map(s => <li key={s}>{s}</li>)}
            </ul>
          </div>
        );
      }
    }
  }

  return (
    <div>
      <Grid
        rows={rows}
        columns={rows === undefined || rows.length === 0 ? [] : columns}
        size='small'
      >
        <DataTypeProvider
          for={integerColumns}
          availableFilterOperations={numericFilterOperations}
          editorComponent={IntegerEditor}
        />
        <DataTypeProvider
          for={floatColumns}
          availableFilterOperations={numericFilterOperations}
          editorComponent={FloatEditor}
        />
        <DataTypeProvider
          for={stringColumns}
          availableFilterOperations={stringFilterOperations}
          editorComponent={StringEditor}
        />
        <DragDropProvider />
        <SortingState
        />
        <PagingState
          defaultCurrentPage={0}
          defaultPageSize={10}
        />
        <SelectionState
          selection={selection} 
          onSelectionChange={setSelection}
        />
        <SearchState />
        <FilteringState
          defaultFilters={[]}
          onFiltersChange={handleFiltersChange}
          filters={[...filters, ...toolbarFilters]}
        />
        <GroupingState
          grouping={grouping}
          onGroupingChange={setGrouping}
        />
        <EditingState
        />
        <IntegratedGrouping />
        <IntegratedFiltering />
        <IntegratedSorting />
        <IntegratedPaging />
        <IntegratedSelection />
        <Table
          noDataCellComponent={NoData}
          cellComponent={CellComponent}
          columnExtensions={tableColumnExtensions}
        />
        <TableSelection />
        <TableHeaderRow
          showSortingControls
          cellComponent={HeaderCellComponent}
        />
        <TableEditRow
        />

        <Toolbar />
        {props.addRequestEndpoint &&
        <RequestButton
          label={props.addRequestButtonLabel}
          onChange={handleAddRequest}
          disabled={selection === undefined || selection.length === 0}
        />
        }
        {props.deleteRequestEndpoint &&
        <RequestButton
          label={props.deleteRequestButtonLabel}
          onChange={handleDeleteRequest}
          disabled={selection === undefined || selection.length === 0}

        />
        }
        <ToolbarFilters
          columnNames={filterColumns}
          filterColumnsDict={filterColumnsDict}
          onFilterChange={handleToolbarFilterChange}
        />
        <PagingPanel
          pageSizes={[10, 25, 50]}
        />
        <SearchPanel />
        <TableFilterRow
          showFilterSelector
        />
        <TableGroupRow />
        <TableColumnVisibility
          defaultHiddenColumnNames={hiddenColumnNames}
          hiddenColumnNames={hiddenColumnNames}
          onHiddenColumnNamesChange={handleHiddenColumnNamesChange}
        />
        <ColumnChooser />
        <ExportPanel startExport={startExport} />
        <GroupingPanel showGroupingControls />
        <TableFixedColumns
          leftColumns={leftColumns}
        />
      </Grid>
      <GridExporter
        ref={exporterRef}
        rows={rows}
        columns={visibleColumns}
        selection={selection}
        onSave={onSave}
      />
      <Snackbar
        open={snackBarOpen}
        onClose={handleSnackBarClose}
        autoHideDuration={6000}
        message={makeSnackBarMessage(snackBarMessage)}
        action={
          <React.Fragment>
            <IconButton
              aria-label="close"
              color="inherit"
              onClick={handleSnackBarClose}
            >
              <CloseIcon />
            </IconButton>
          </React.Fragment>
        }
      />
      {loading && <Loading />}
    </div>
  );
}
export default TableBase;