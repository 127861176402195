import React from 'react';

import {TableBase} from './TableBase.js';

import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

function TabPanel(props) {
  const {children, value, index, ...other} = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    'id': `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


export function MicroTabs(props) {
  const [value, setValue] = React.useState(Number(localStorage.getItem('microTabValue')));

  const handleChange = (event, newValue) => {
    localStorage.setItem('microTabValue', newValue);
    setValue(newValue);
  };


  return (
    <div sx={{ flexGrow: 1, bgcolor: 'text.secondary'}}>
      <Paper square>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="micro-tabs"
        >
          <Tab label="Daily" {...a11yProps(0)} />
          <Tab label="Quarterly" {...a11yProps(1)} />
          <Tab label="Q on Q" {...a11yProps(2)} />

        </Tabs>
      </Paper>
      <TabPanel value={value} index={0}>
        <TableBase
          dataEndpoint="/micro-daily-max-min"
        
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <TableBase
          dataEndpoint="/micro-quarterly-max-min"
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <TableBase
          dataEndpoint="/micro-quarterly-q-on-q"
        />
      </TabPanel>
    </div>
  );
}

export default MicroTabs;
