import React from 'react';
import AppBar from './AppBar.js';
import ChartImage from './ChartImage.js';

function Chart(props) {
  return (
    <div>
      <AppBar {...props}/>
      <ChartImage {...props}/>
    </div>
  );
}

export default Chart;