import React from 'react';
import MicroTabs from './MicroTabs.js';
import AppBar from './AppBar.js';

function Micro(props) {
  return (
    <div>
      <AppBar {...props}/>
      <MicroTabs {...props}/>
    </div>
  );
}

export default Micro;