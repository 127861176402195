export const hiddenColumnsSettings = [
  'score_pct',
  'score_nom',
  'spread_pct',
  'spread_nom',
  'pl_pct',
  'opf_pct',
  'spread_pct_31',
  'spread_nom_31',
  'pl_pct_31',
  'opf_pct_31',
  'spread_pct_30',
  'spread_nom_30',
  'pl_pct_30',
  'opf_pct_30',
  'spread_pct_60',
  'spread_nom_60',
  'pl_pct_60',
  'opf_pct_60',
  'spread_pct_90',
  'spread_nom_90',
  'pl_pct_90',
  'opf_pct_90',
  'spread_pct_62',
  'spread_nom_62',
  'pl_pct_62',
  'opf_pct_62',
  'spread_pct_182',
  'spread_nom_182',
  'pl_pct_182',
  'opf_pct_182',
  'score_pct_31',
  'score_pct_62',
  'score_pct_182',
  'score_nom_31',
  'score_nom_62',
  'score_nom_182',
  'score_pct_30',
  'score_nom_30',
  'score_pct_90',
  'score_nom_90',
  'score_pct_60',
  'score_nom_60',
  'start',
  'end',
  'first_signal',
  'indicator_type',
  'spread_nom',
  'score_nom',
  'nwins',
  'nlosses',
  'nwins_182',
  'nlosses_182',
  'nwins_62',
  'nlosses_62',
  'nwins_31',
  'nlosses_31',
  'nwins_30',
  'nlosses_30',
  'nwins_60',
  'nlosses_60',
  'nwins_90',
  'nlosses_90',
  'optimization_time_window',
  'sector',
  'indexes',
  'symbol_type',
]

export const tableColumnExtensionsSettings = [
  { columnName: 'security_name', width: '95' },
  { columnName: 'position', width: '95' },
  { columnName: 'last_signal', width: '95' },
  { columnName: 'current_signal', width: '95' },
  { columnName: 'pl_pct', width: '95' },
  { columnName: 'pl_nom', width: '95' },
  { columnName: 'spread_pct', width: '95' },
  { columnName: 'spread_nom', width: '95' },
  { columnName: 'opf_pct', width: '95' },
  { columnName: 'opf_nom', width: '95' },
  { columnName: 'score_pct', width: '95' },
  { columnName: 'score_nom', width: '95' },
  { columnName: 'pl_pct_31', width: '95' },
  { columnName: 'pl_nom_31', width: '95' },
  { columnName: 'spread_pct_31', width: '95' },
  { columnName: 'spread_nom_31', width: '95' },
  { columnName: 'opf_pct_31', width: '95' },
  { columnName: 'opf_nom_31', width: '95' },
  { columnName: 'score_pct_31', width: '95' },
  { columnName: 'score_nom_31', width: '95' },
  { columnName: 'pl_pct_62', width: '95' },
  { columnName: 'pl_nom_62', width: '95' },
  { columnName: 'spread_pct_62', width: '95' },
  { columnName: 'spread_nom_62', width: '95' },
  { columnName: 'opf_pct_62', width: '95' },
  { columnName: 'opf_nom_62', width: '95' },
  { columnName: 'score_pct_62', width: '95' },
  { columnName: 'score_nom_62', width: '95' },
  { columnName: 'pl_pct_182', width: '95' },
  { columnName: 'pl_nom_182', width: '95' },
  { columnName: 'spread_pct_182', width: '95' },
  { columnName: 'spread_nom_182', width: '95' },
  { columnName: 'opf_pct_182', width: '95' },
  { columnName: 'opf_nom_182', width: '95' },
  { columnName: 'score_pct_182', width: '95' },
  { columnName: 'score_nom_182', width: '95' },

  { columnName: 'pl_pct_30', width: '95' },
  { columnName: 'pl_nom_30', width: '95' },
  { columnName: 'spread_pct_30', width: '95' },
  { columnName: 'spread_nom_30', width: '95' },
  { columnName: 'opf_pct_30', width: '95' },
  { columnName: 'opf_nom_30', width: '95' },
  { columnName: 'score_pct_30', width: '95' },
  { columnName: 'score_nom_30', width: '95' },

  { columnName: 'pl_pct_60', width: '95' },
  { columnName: 'pl_nom_60', width: '95' },
  { columnName: 'spread_pct_60', width: '95' },
  { columnName: 'spread_nom_60', width: '95' },
  { columnName: 'opf_pct_60', width: '95' },
  { columnName: 'opf_nom_60', width: '95' },
  { columnName: 'score_pct_60', width: '95' },
  { columnName: 'score_nom_60', width: '95' },

  { columnName: 'pl_pct_90', width: '95' },
  { columnName: 'pl_nom_90', width: '95' },
  { columnName: 'spread_pct_90', width: '95' },
  { columnName: 'spread_nom_90', width: '95' },
  { columnName: 'opf_pct_90', width: '95' },
  { columnName: 'opf_nom_90', width: '95' },
  { columnName: 'score_pct_90', width: '95' },
  { columnName: 'score_nom_90', width: '95' },

  { columnName: 'hit_30', width: '95' },
  { columnName: 'ntrades_30', width: '95' },
  { columnName: 'nwins_30', width: '95' },

  { columnName: 'hit_60', width: '95' },
  { columnName: 'ntrades_60', width: '95' },
  { columnName: 'nwins_60', width: '95' },

  { columnName: 'hit_90', width: '95' },
  { columnName: 'ntrades_90', width: '95' },
  { columnName: 'nwins_90', width: '95' },

  { columnName: 'hit', width: '95' },
  { columnName: 'ntrades', width: '95' },
  { columnName: 'nwins', width: '95' },
  { columnName: 'nlosses', width: '95' },
  { columnName: 'hit_182', width: '95' },
  { columnName: 'ntrades_182', width: '95' },
  { columnName: 'nwins_182', width: '95' },
  { columnName: 'hit_62', width: '95' },
  { columnName: 'ntrades_62', width: '95' },
  { columnName: 'nwins_62', width: '95' },
  { columnName: 'hit_31', width: '95' },
  { columnName: 'ntrades_31', width: '95' },
  { columnName: 'nwins_31', width: '95' },
  { columnName: 'indicator_type', width: '95' },
  { columnName: 'start', width: '95' },
  { columnName: 'end', width: '95' },
  { columnName: 'first_signal', width: '95' },
  { columnName: 'config', width: '95' },
  { columnName: 'optimization_time_window', width: '95' },
  { columnName: 'sector', width: '95' },
  { columnName: 'symbol_type', width: '95' },
  { columnName: 'indexes', width: '95' },
  { columnName: 'indexes', width: '95' },
];


export const totalSummaryItemsSettings = [
  { columnName: 'hit', type: 'max' },
  { columnName: 'spread', type: 'max' },
  { columnName: 'ntrades', type: 'max' },
  { columnName: 'nwins', type: 'max' },
  { columnName: 'nlosses', type: 'max' },
  { columnName: 'opf_pct', type: 'max' },
  { columnName: 'opf_nom', type: 'max' },
  { columnName: 'pl_pct', type: 'max' },
  { columnName: 'pl_nom', type: 'max' },
];

export const stringColumnsSettings = [
  'security_name',
  'indexes',
  'optimization_time_window',
  'position',
  'sector',
  'symbol_type',
  'start',
  'end',
  'first_signal',
  'config',
  'last_signal',
  'current_signal',
];


export const filterColumnsSettings = [
  'sector',
  'indexes',
  'symbol_type',
];

export const floatColumnsSettings = [
  'hit',
  'hit_31',
  'hit_62',
  'hit_182',
  'hit_30',
  'hit_60',
  'hit_120',
  'score_pct',
  'pl_pct',
  'spread_pct',
  'opf_pct',
  'pl_nom',
  'spread_nom',
  'opf_nom',
  'score_pct_31',
  'pl_pct_31',
  'spread_pct_31',
  'opf_pct_31',
  'pl_nom_31',
  'spread_nom_31',
  'opf_nom_31',
  'score_pct_62',
  'score_nom_62',
  'pl_pct_62',
  'spread_pct_62',
  'opf_pct_62',
  'pl_nom_62',
  'spread_nom_62',
  'opf_nom_62',
  'score_pct_182',
  'score_nom_182',
  'pl_pct_182',
  'spread_pct_182',
  'opf_pct_182',
  'pl_nom_182',
  'spread_nom_182',
  'opf_nom_182',
  'score_pct_30',
  'score_nom_30',
  'pl_pct_30',
  'spread_pct_30',
  'opf_pct_30',
  'pl_nom_30',
  'spread_nom_30',
  'opf_nom_30',
  'score_pct_60',
  'score_nom_60',
  'pl_pct_60',
  'spread_pct_60',
  'opf_pct_60',
  'pl_nom_60',
  'spread_nom_60',
  'opf_nom_60',
  'score_pct_90',
  'score_nom_90',
  'pl_pct_90',
  'spread_pct_90',
  'opf_pct_90',
  'pl_nom_90',
  'spread_nom_90',
  'opf_nom_90',
];



export const integerColumnsSettings = [
  'nlosses',
  'nwins',
  'ntrades',
  'nlosses_182',
  'nwins_182',
  'ntrades_182',
  'nlosses_62',
  'nwins_62',
  'ntrades_62',
  'nlosses_31',
  'nwins_31',
  'ntrades_31',
  'nlosses_30',
  'nwins_30',
  'ntrades_30',
  'nlosses_60',
  'nwins_60',
  'ntrades_60',
  'nlosses_90',
  'nwins_90',
  'ntrades_90',
];
