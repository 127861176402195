import React from 'react';
import IndicatorTabs from './IndicatorTabs.js';
import AppBar from './AppBar.js';

function Indicators(props) {
  return (
    <div>
      <AppBar {...props}/>
      <IndicatorTabs {...props}/>
    </div>
  );
}

export default Indicators;
