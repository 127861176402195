import React, { useState, useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import {styled} from '@mui/system';
import { EditingState } from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableEditRow,
  TableEditColumn,
  Toolbar,
} from '@devexpress/dx-react-grid-material-ui';
import LinearProgress from '@mui/material/LinearProgress';
import {getToken, removeUserSession} from './Utils/Common';
import axios from 'axios';
import {
  Plugin,
  Template,
  TemplatePlaceholder,
} from '@devexpress/dx-react-core';

const getRowId = row => row.id;

const StyledComponent = styled('div')({
  width: '10%',
  '& > * + *': {
    marginTop: theme => theme.spacing(2),
  },
});

export const Loading = () => (
  <div className="loading-shading-mui">
    <LinearProgress className="loading-icon-mui" />
  </div>
);

function LinearIndeterminate() {
  return (
    <StyledComponent>
      <LinearProgress />
    </StyledComponent>
  );
}


function NoData() {
  return (
    <td>Loading...</td>
  );
}


function createRows(data) {
  var rows = [];
  const dataCopy = JSON.parse(JSON.stringify(data));
  let i = 0;

  for (const [security_name, values] of Object.entries(dataCopy)) {

    rows[i++] = {
      id:i,
      security_name: security_name,
      sector: values.sector,
      indexes: values.indexes ? values.indexes.join(', ') : '',
      symbol_type: values.symbol_type,
    }
  }
  return rows;
}

const pluginDependencies = [
  { name: 'Toolbar' }
];


export const UpdateMetaDataRequestButton = (props) => {

  const handleClick = () => {
    props.onChange();
  };

  return (
    <Plugin
      name="ToolbarUrlParameter"
      dependencies={pluginDependencies}
    >
      <Template name="toolbarContent">
        <TemplatePlaceholder />
        <Button onClick={handleClick} disabled={props.disabled}>
          Update Portfolio
        </Button>
      </Template>
    </Plugin>
  );
}

export const PortfolioEditor = (props) => {
  const [columns] = useState([
    { name: 'security_name', title: 'security_name' },
    { name: 'symbol_type', title: 'symbol_type' },
    { name: 'indexes', title: 'indexes' },
    { name: 'sector', title: 'sector' },
  ]);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);

  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState();

  const handleUpdateMetaData = () => {

    const token = getToken();
    if (!token) {
      return;
    }
    let url = new URL(process.env.REACT_APP_API_URL);

    url.pathname += '/update-metadata';

    axios.post(url, {
      metadata: rows
    }, {
      auth: {
        username: token,
        password: 'unused',
      },
    },
    
    )
      .then((response) => {
        setSnackBarMessage(response.data);
        setSnackBarOpen(true);

      }).catch((error) => {
        removeUserSession();
        props.history.push('/login');
      });
  };

  useEffect(() => {
    const token = getToken();
    if (!token) {
      return;
    }
    let url = new URL(process.env.REACT_APP_API_URL);
    url.pathname += '/metadata';

    axios.get(
      url,
      {
        auth: {
          username: token,
          password: 'unused',
        }
      },
    )
      .then((response) => {
        const rows = createRows(response.data);
        setRows(rows);
        setLoading(false);

      }).catch((error) => {
        removeUserSession();
        props.history.push('/login');
      });
  }, [props.history]);

  if (loading) {
    return (
      <LinearIndeterminate />
    );
  };

  const commitChanges = ({ added, changed, deleted }) => {
    let changedRows;
    if (added) {
      const startingAddedId = rows.length > 0 ? rows[rows.length - 1].id + 1 : 0;
      changedRows = [
        ...rows,
        ...added.map((row, index) => ({
          id: startingAddedId + index,
          ...row,
        })),
      ];
    }
    if (changed) {
      changedRows = rows.map(row => (changed[row.id] ? { ...row, ...changed[row.id] } : row));
    }
    if (deleted) {
      const deletedSet = new Set(deleted);
      changedRows = rows.filter(row => !deletedSet.has(row.id));
    }
    setRows(changedRows);
  };

  const handleSnackBarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackBarOpen(false);
  }

  const makeSnackBarMessage = (message) => {
    if (message) {
      if (message.deleted_items && message.added_items) {
        return (
          <div>
            Deleted<br/>
            <ul>
              {message.deleted_items.map(s => <li key={s}>{s}</li>)}
            </ul>
            Added<br/>
            <ul>
              {message.added_items.map(s => <li key={s}>{s}</li>)}
            </ul>
          </div>
        );
      }
      else if (message.deleted_items) {
        return (
          <div>
            Deleted<br/>
            <ul>
              {message.deleted_items.map(s => <li key={s}>{s}</li>)}
            </ul>
          </div>
        );
      }
      else if (message.added_items) {
        return (
          <div>
            Added<br/>
            <ul>
              {message.added_items.map(s => <li key={s}>{s}</li>)}
            </ul>
          </div>
        );
      }
      else {
        return (
          <div>
            No new securities...
          </div>
        );

      }
    }
  }
  return (
    <Paper>
      <Grid
        rows={rows}
        columns={columns}
        getRowId={getRowId}
      >
        <EditingState
          onCommitChanges={commitChanges}
        />
        <Table
          noDataCellComponent={NoData}
        />
        <TableHeaderRow />
        <TableEditRow />
        <TableEditColumn
          showAddCommand
          showEditCommand
          showDeleteCommand
        />
        <Toolbar />
        <UpdateMetaDataRequestButton
          onChange={handleUpdateMetaData}
        />
      </Grid>
      <Snackbar
        open={snackBarOpen}
        onClose={handleSnackBarClose}
        autoHideDuration={6000}
        message={makeSnackBarMessage(snackBarMessage)}
        action={
          <React.Fragment>
            <IconButton
              aria-label="close"
              color="inherit"
              onClick={handleSnackBarClose}
            >
              <CloseIcon />
            </IconButton>
          </React.Fragment>
        }
      />
      {loading && <Loading />}
    </Paper>
  );
};


export default PortfolioEditor;
