import React, {useState, useRef, useCallback, useEffect} from 'react';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import * as PropTypes from 'prop-types';
import Input from '@mui/material/Input';
import axios from 'axios';
import {getToken, removeUserSession} from './Utils/Common';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import MenuItem from '@mui/material/MenuItem';
import {
  Grid,
  VirtualTable,
  TableHeaderRow,
  TableBandHeader,
  TableSelection,
  PagingPanel,
  SearchPanel,
  Toolbar,
  DragDropProvider,
  TableColumnReordering,
  ColumnChooser,
  TableColumnVisibility,
  ExportPanel,
  TableGroupRow,
  GroupingPanel,
  TableEditRow,
  TableEditColumn,
} from '@devexpress/dx-react-grid-material-ui';
import {
  SelectionState,
  PagingState,
  IntegratedPaging,
  IntegratedSelection,
  SortingState,
  IntegratedSorting,
  SearchState,
  IntegratedFiltering,
  FilteringState,
  GroupingState,
  IntegratedGrouping,
  DataTypeProvider,
  EditingState,
} from '@devexpress/dx-react-grid';
import saveAs from 'file-saver';
import LinearProgress from '@mui/material/LinearProgress';
import {
  Plugin,
  Template,
  TemplatePlaceholder,
} from '@devexpress/dx-react-core';
import IconButton from '@mui/material/IconButton';
import ShowChartIcon from '@mui/icons-material/ShowChart';

const pluginDependencies = [
  { name: 'Toolbar' }
];


export const FilterSelector = (props) => {
  const [selectedItem, setSelectedItem] =  useState('');
  const { columnName, items } = props;

  const handleChange = (event) => {
    setSelectedItem(event.target.value);
    props.onChange(columnName, event.target.value);
  };

  const handleClick = (event) => {
    setSelectedItem('');
    props.onClick(columnName);
  };

  return (
    <div key={columnName}>
      <InputLabel htmlFor="filter-field">
        {columnName}
      </InputLabel>
      <Select onChange={handleChange} value={selectedItem}>
        {items.map(item => {
          if (item === null) {
            return null;
          }
          return <MenuItem key={item} value={item}>{item}</MenuItem>;
        })}   
      </Select>
      <Button
        onClick={(e) => handleClick(e)}
        key={columnName}
        value={columnName}
      >
        Clear
      </Button>
    </div>
  );
};

export const ToolbarFilters = (props) => {
  const [filters, setFilters] = useState({});

  const handleChange = (columnName, value) => {
    const fMap = {
      ...filters,
      [columnName]: {
        columnName: columnName,
        value: value,
        operation: 'equal',
      }
    }
    props.onFilterChange(Object.values(fMap));
    setFilters(fMap);
  };

  const handleClick = (columnName) => {
    const fMap = filters;
    delete fMap[columnName];
    props.onFilterChange(Object.values(fMap));
    setFilters(fMap);
  };

  return (
    <Plugin
      name="ToolbarFilter"
      dependencies={pluginDependencies}
    >
      <Template name="toolbarContent">
        <TemplatePlaceholder />
        {
          props.columnNames.map(columnName => {
            return <FilterSelector
              key={columnName}
              columnName={columnName}
              items={Array.from(props.filterColumnsDict[columnName])}
              onChange={handleChange}
              onClick={handleClick}
            />
          })
        }
      </Template>
    </Plugin>
  );
}


export const Loading = () => (
  <div className="loading-shading-mui">
    <LinearProgress className="loading-icon-mui" />
  </div>
);

function LinearIndeterminate() {
  return (
    <div>
      <LinearProgress />
    </div>
  );
}


function NoData() {
  return (
    <td>Loading...</td>
  );
}


const CellComponent = (props) => {
  const { column, row } = props;

  if (column.name.endsWith('_2') ||
    column.name.endsWith('_5')  ||
    column.name.endsWith('_7')) {


    if (row[column.name] < 0) {
      return (
        <VirtualTable.Cell
          {...props}
          style={{
            padding: '1',
            fontSize: '10px',
            width: '10%',
            color: 'red',
          }}
        />
      );

    }
    if (row[column.name] > 0) {
      return (
        <VirtualTable.Cell
          {...props}
          style={{
            padding: '1',
            fontSize: '10px',
            width: '10%',
            color: 'green',
          }}
        />
      );

    }
    return (
      <VirtualTable.Cell
        {...props}
        style={{
          padding: '1',
          fontSize: '10px',
          width: '10%',
        }}
      />
    );
  }
  return (
    <VirtualTable.Cell
      {...props}
      style={{
        padding: '1',
        fontSize: '10px',
        width: '10%',
      }}
    />
  );
};


const HeaderCellComponent = (props) => (
  <TableHeaderRow.Cell
    {...props}
    style={{
      fontSize: '10px',
    }}
  />
);


function createColumnNames(data) {
  const dataCopy = JSON.parse(JSON.stringify(data));
  const keys = Object.keys(dataCopy);

  let index = keys.indexOf('position');
  keys.unshift(keys.splice(index, 1)[0]);

  index = keys.indexOf('security_name');
  keys.unshift(keys.splice(index, 1)[0]);

  return keys;
}


function createColumnBands(data) {
  const dataCopy = JSON.parse(JSON.stringify(data));
  const myMap = {};

  for (const name of Object.keys(dataCopy)) {

    if (name.startsWith('daily') || name.startsWith('weekly')) {

      let splits = name.split('_');
      let freqAndPeriod = splits[0] + '_' + splits[1];
      
      if (freqAndPeriod in myMap) {
        myMap[freqAndPeriod].push(name);
      } else {
        myMap[freqAndPeriod] = [name];
      }
    }
  }

  let res = [];

  for (const [freqAndPeriod, columnNames] of Object.entries(myMap)){
      
    let children = [];
    
    for (const columnName of Object.values(columnNames)){

      children.push({columnName: columnName});

    }
    let item = { title: freqAndPeriod, children: children};
    res.push(item);
  }

  return res;
}


function createColumns(data) {
  const dataCopy = JSON.parse(JSON.stringify(data));
  const dailyColumns = {};
  const weeklyColumns = {};
  const nonBandedColumns = [];

  for (const name of Object.keys(dataCopy)) {

    if (name.startsWith('daily')) {
      const splits = name.split('_');
      const num = splits[splits.length -1];
      if (num === '0') {
        dailyColumns[name] = '№';
      } else {
        dailyColumns[name] = 'Δ ' + num;
      }
    } else if (name.startsWith('weekly')) {
      const splits = name.split('_');
      const num = splits[splits.length -1];
      if (num === '0') {
        weeklyColumns[name] = '№';
      } else {
        weeklyColumns[name] = 'Δ ' + num;
      }
    } else {
      nonBandedColumns.push({name: name, title: name});

    }
  }

  const dailySorted = [];

  for (const key of Object.keys(dailyColumns).sort()) {
    dailySorted.push({name: key, title: dailyColumns[key]});

  }
  const weeklySorted = [];

  for (const key of Object.keys(weeklyColumns).sort()) {
    weeklySorted.push({name: key, title: weeklyColumns[key]});

  }

  return dailySorted.concat(weeklySorted).concat(nonBandedColumns);
}


function createRows(data) {
  var rows = [];
  const dataCopy = JSON.parse(JSON.stringify(data));

  for (const [name, values] of Object.entries(dataCopy)) {

    for (const [i, value] of Object.values(values).entries()) {

      if (!rows[i]) {
        rows[i] = Object()
      }
      rows[i][name] = value
    }
  }
  return rows;
}


const onSave = (workbook) => {
  workbook.xlsx.writeBuffer().then((buffer) => {
    saveAs(
      new Blob([buffer],{ type: 'application/octet-stream' }),
      'Rankings.xlsx'
    );
  });
};


const IntegerEditor = ({ value, onValueChange }) => {
  const handleChange = (event) => {
    const { value: targetValue } = event.target;
    if (targetValue.trim() === '') {
      onValueChange();
      return;
    }
    onValueChange(parseInt(targetValue, 10));
  };
  return (
    <Input
      type="number"
      fullWidth
      value={value === undefined ? '' : value}
      inputProps={{
        min: 0,
        placeholder: '...',
      }}
      onChange={handleChange}
    />
  );
};

IntegerEditor.propTypes = {
  value: PropTypes.number,
  onValueChange: PropTypes.func.isRequired,
};

IntegerEditor.defaultProps = {
  value: undefined,
};


const StringEditor = ({ value, onValueChange }) => {

  const handleChange = (event) => {

    const { value: targetValue } = event.target;

    if (targetValue.trim() === '') {
      onValueChange();
      return;
    }

    onValueChange(targetValue);
  };

  return (
    <Input
      type="text"
      fullWidth
      value={value === undefined ? '' : value}
      inputProps={{
        min: 0,
        placeholder: '...',
      }}
      onChange={handleChange}
    />
  );
};

StringEditor.propTypes = {
  onValueChange: PropTypes.func.isRequired,
};

StringEditor.defaultProps = {
  value: undefined,
};


function createFilterColumnDict(rows, filterColumns) {
  let dict = {};
  for (const row of Object.values(rows)) {
    for (const columnName of Object.values(filterColumns)) {

      if (!(columnName in dict)) {
        dict[columnName] = new Set();
      }
      dict[columnName].add(row[columnName]);
    }
  }
  return dict;
}

const Root = props => <Grid.Root {...props} style={{ height: '100%' }} />;


export const RankingTable = (props) => {
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [columnBands, setColumnBands] = useState([]);
  const [loading, setLoading] = useState(true);
  const [columnOrder, setColumnOrder] = useState([]);
  const [numericFilterOperations] = useState([
    'greaterThan',
    'equal',
    'notEqual',
    'greaterThanOrEqual',
    'lessThan',
    'lessThanOrEqual',
  ]);
  const [stringFilterOperations] = useState([
    'contains',
    'notContains',
    'startsWith',
    'endsWith',
    'equal',
    'notEqual'
  ]);


  const exporterRef = useRef(null);

  const startExport = useCallback((options) => {
    exporterRef.current.exportGrid(options);
  },  [exporterRef]);

  const [grouping, setGrouping] = useState([]);
  const [tabData, setTabData] = useState([]);

  useEffect(() => {
    const token = getToken();
    if (!token) {
      return;
    }

    let tabDataUrl = new URL(process.env.REACT_APP_API_URL);
    tabDataUrl.pathname += '/sector-ranking-config';
  
    axios
      .get(tabDataUrl, {
        auth: {
          username: token,
          password: 'unused',
        },
      })
      .then((response) => {
        setTabData(response.data); // Store the tab data in state
      })
      .catch((error) => {
        console.error('Error fetching tab data:', error);
      });

    let rankingUrl = new URL(process.env.REACT_APP_API_URL);
    rankingUrl.pathname += '/ranking';
    rankingUrl.search = `list=${props.list}`;
  
    axios
      .get(rankingUrl, {
        auth: {
          username: token,
          password: 'unused',
        },
      })
      .then((response) => {
      })
      .catch((error) => {
        console.error('Error fetching ranking data:', error);
        removeUserSession();
        props.history.push('/login');
      });
  }, [props.list, props.history]);
  

  const categories = [...tabData];
  
  const frequencies = [
    "daily_10",
    "daily_20",
    "daily_30",
    "daily_40",
    "weekly_27"
  ];
  
  const periods = [
    "0",
    "1",
    "2",
    "5"
  ];
  
  const integerColumns = [];
  
  for (const category of categories) {
    for (const frequency of frequencies) {
      for (const period of periods) {
        const column = `${frequency}_${category}_${period}`;
        integerColumns.push(column);
      }
    }
  }
  const [filterColumns] = useState([
    'sector',
    'indexes',
    'symbol_type',
  ]);

  const [stringColumns] = useState([
    'security_name',
    'last_price',
    'sector',
    'symbol_type',
    'indexes',
  ]);

  const [filterColumnsDict, setFilterColumnsDict] = useState();
  const [filters, setFilters]  = useState([]);
  const [toolbarFilters, setToolbarFilters]  = useState([]);
  const [selection, setSelection] = useState([]);

  const [hiddenColumnNames, setHiddenColumnNames] = useState(
    [
    ].concat(filterColumns)
  );

  const [visibleColumns, setVisibleColumns] = useState();

  const [filteringColumnExtensions] = useState([
    {
      columnName: 'last_signal',
      predicate: (value, filter, row) => {
        if (!filter.value.length) return true;
        if (filter && filter.operation === 'month') {
          const month = parseInt(value.split('-')[1], 10);
          return month === parseInt(filter.value, 10);
        }
        if (filter && filter.operation === 'year') {
          const year = parseInt(value.split('-')[0], 10);
          return year === parseInt(filter.value, 10);
        }
        if (filter && filter.operation === 'day') {
          const day = parseInt(value.split('-')[2], 10);
          return day === parseInt(filter.value, 10);
        }
        return IntegratedFiltering.defaultPredicate(value, filter, row);
      },
    },
  ]);


  const tableColumnExtensions = frequencies.flatMap(frequency =>
    categories.flatMap(category =>
      periods.map(period => ({
        columnName: `${frequency}_${category}_${period}`,
        width: "60px"
      }))
    )
  );


  useEffect(() => {
    const token = getToken();
    if (!token) {
      return;
    }
    let url = new URL(process.env.REACT_APP_API_URL);
    url.pathname += '/ranking';
    url.search = `list=${props.list}`;


    axios.get(
      url,
      {
        auth: {
          username: token,
          password: 'unused',
        }
      },
    )

      .then((response) => {
        const columnBands = createColumnBands(response.data);
        const columns = createColumns(response.data);
        const rows = createRows(response.data);
        const hiddenColumns = hiddenColumnNames.map(
          k => ({name: k, title: k})
        );
        const visibleColumns = columns.filter(
          a => !hiddenColumns.map(b=>b.name).includes(a.name)
        );

        setColumns(columns);
        setRows(rows);
        setColumnOrder(createColumnNames(response.data));
        setVisibleColumns(visibleColumns);
        setFilterColumnsDict(createFilterColumnDict(rows, filterColumns));
        setColumnBands(columnBands);
        setLoading(false);

      }).catch((error) => {
        removeUserSession();
        props.history.push('/login');
      });
  }, [filterColumns, hiddenColumnNames, props.history, props.list]);


  if (loading) {
    return (
      <LinearIndeterminate />
    );
  };

  const handleToolbarFilterChange = (filter) => {
    setToolbarFilters(filter);
  };

  const handleFiltersChange = (event) => {
    let set = new Set();
    toolbarFilters.forEach(item => set.add(item));
    event.forEach(item => set.add(item));
    setFilters(Array.from(set));
  };

  const handleHiddenColumnNamesChange = (hiddenColumnNames) => {
    const hiddenColumns = hiddenColumnNames.map(k => ({name: k, title: k}))
    const visibleColumns = columns.filter(
      a => !hiddenColumns.map(b=>b.name).includes(a.name)
    );
    setHiddenColumnNames(hiddenColumnNames);
    setVisibleColumns(visibleColumns);
  };

  const cellComponent = (props) => {
    const href = "chart/" +
      `${encodeURI(props.tableRow.row.config)}/` +
      `${encodeURI(props.tableRow.row.security_name)}/` +
      `${encodeURI(props.tableRow.row.position)}/`

    return (
      <TableEditColumn.Cell {...props}>
        <IconButton
          color="secondary"
          title="Show Chart"
          href={href}
        >
          <ShowChartIcon />
        </IconButton>
      </TableEditColumn.Cell>
    );
  }

  return (
      <Paper
        style={{
        height: '565px',
        padding: '0',
        }}
        m={0}
      >
      <Grid
        rows={rows}
        columns={columns}
        size='small'
        rootComponent={Root}
      >
        <DataTypeProvider
          for={integerColumns}
          availableFilterOperations={numericFilterOperations}
          editorComponent={IntegerEditor}
        />
        <DataTypeProvider
          for={stringColumns}
          availableFilterOperations={stringFilterOperations}
          editorComponent={StringEditor}
        />
        <DragDropProvider />
        <SortingState
        />
        <PagingState
          defaultCurrentPage={0}
          defaultPageSize={0}
        />
        <SelectionState
          selection={selection} 
          onSelectionChange={setSelection}
        />
        <SearchState />
        <FilteringState
          defaultFilters={[]}
          onFiltersChange={handleFiltersChange}
          filters={[...filters, ...toolbarFilters]}
        />
        <GroupingState
          grouping={grouping}
          onGroupingChange={setGrouping}
        />
        <EditingState
        />
        <IntegratedGrouping />
        <IntegratedFiltering  columnExtensions={filteringColumnExtensions}/>
        <IntegratedSorting />
        <IntegratedPaging />
        <IntegratedSelection />
        <VirtualTable
          noDataCellComponent={NoData}
          cellComponent={CellComponent}
          columnExtensions={tableColumnExtensions}
          height='auto'
        />
        <TableSelection />
        <TableColumnReordering
          order={columnOrder}
          onOrderChange={setColumnOrder}
        />
        <TableHeaderRow
          showSortingControls
          cellComponent={HeaderCellComponent}
        />
        <TableEditRow
        />
        <TableEditColumn
          width={50}
          cellComponent={cellComponent}
        />

        <TableColumnVisibility
          defaultHiddenColumnNames={hiddenColumnNames}
          hiddenColumnNames={hiddenColumnNames}
          onHiddenColumnNamesChange={handleHiddenColumnNamesChange}
        />
        <TableGroupRow />
        <TableBandHeader
          columnBands={columnBands}
        />

        <Toolbar />
        <ToolbarFilters
          columnNames={filterColumns}
          filterColumnsDict={filterColumnsDict}
          onFilterChange={handleToolbarFilterChange}
        />
        <PagingPanel
          pageSizes={[10, 25, 50, 0]}
        />
        <SearchPanel />
        <ColumnChooser />
        <ExportPanel startExport={startExport} />
        <GroupingPanel showGroupingControls />
      </Grid>
      <GridExporter
        ref={exporterRef}
        rows={rows}
        columns={visibleColumns}
        selection={selection}
        onSave={onSave}
      />
      {loading && <Loading />}
    </Paper>
  );
}

export default RankingTable;
