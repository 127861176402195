import React from 'react';
import {TableBase} from './TableBase.js';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

function TabPanel(props) {
  const {children, value, index, ...other} = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    'id': `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


export function IndicatorTabs(props) {
  const [value, setValue] = React.useState(Number(localStorage.getItem('indicatorTabValue')));


  const handleChange = (event, newValue) => {
    localStorage.setItem('indicatorTabValue', newValue);
    setValue(newValue);
  };


  return (
    <div sx={{ flexGrow: 1, bgcolor: 'text.secondary'}}>
      <Paper square>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="indicator-tabs"
        >
          <Tab label="Optimization Result" {...a11yProps(0)} />
          <Tab label="Pooling Indicators" {...a11yProps(1)} />
          <Tab label="Selected Indicators" {...a11yProps(2)} />
          <Tab label="MACD CROSS" {...a11yProps(3)} />
          <Tab label="MACD STD" {...a11yProps(4)} />
          <Tab label="MACD WEEKLY" {...a11yProps(5)} />
          <Tab label="VOLUME" {...a11yProps(6)} />
          <Tab label="SMA" {...a11yProps(7)} />
          <Tab label="RS" {...a11yProps(8)} />
          <Tab label="PUT CALL RATIO" {...a11yProps(9)} />
          <Tab label="LONG SE" {...a11yProps(10)} />
          <Tab label="SHORT SE" {...a11yProps(11)} />
        </Tabs>
      </Paper>
      <TabPanel value={value} index={0}>
        <TableBase
          dataEndpoint="/optimization-results"
          addRequestEndpoint="/add-pooling"
          addRequestButtonLabel="Add to pooling"
        
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <TableBase
          dataEndpoint="/get-pooling"
          poolingEndpoint="/get-pooling"
          addRequestEndpoint="/add-selected"
          addRequestButtonLabel="Add to selected"
          deleteRequestEndpoint="/delete-pooling"
          deleteRequestButtonLabel="delete pooling"
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <TableBase
          dataEndpoint="/get-selected"
          poolingEndpoint="/get-pooling"
          deleteRequestEndpoint="/delete-selected"
          deleteRequestButtonLabel="delete selected"
        />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <TableBase
          dataEndpoint="/indicators-macd-cross"
          addRequestEndpoint="/add-pooling"
          addRequestButtonLabel="Add to pooling"
        />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <TableBase
          dataEndpoint="/indicators-macd-std"
          addRequestEndpoint="/add-pooling"
          addRequestButtonLabel="Add to pooling"
        />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <TableBase
          dataEndpoint="/indicators-macd-weekly"
        />
      </TabPanel>
      <TabPanel value={value} index={6}>
        <TableBase
          dataEndpoint="/indicators-volume"
          addRequestEndpoint="/add-pooling"
          addRequestButtonLabel="Add to pooling"
        />
      </TabPanel>
      <TabPanel value={value} index={7}>
        <TableBase
          dataEndpoint="/indicators-sma"
          addRequestEndpoint="/add-pooling"
          addRequestButtonLabel="Add to pooling"
        />
      </TabPanel>
      <TabPanel value={value} index={8}>
        <TableBase
          dataEndpoint="/indicators-rs"
          addRequestEndpoint="/add-pooling"
          addRequestButtonLabel="Add to pooling"
        />
      </TabPanel>
      <TabPanel value={value} index={9}>
        <TableBase
          dataEndpoint="/indicators-put-call-ratio"
          addRequestEndpoint="/add-pooling"
          addRequestButtonLabel="Add to pooling"
        />
      </TabPanel>
      <TabPanel value={value} index={10}>
        <TableBase
          dataEndpoint="/indicators-long-se"
          addRequestEndpoint="/add-pooling"
          addRequestButtonLabel="Add to pooling"
        />
      </TabPanel>
      <TabPanel value={value} index={11}>
        <TableBase
          dataEndpoint="/indicators-short-se"
          addRequestEndpoint="/add-pooling"
          addRequestButtonLabel="Add to pooling"
        />
      </TabPanel>
    </div>
  );
}

export default IndicatorTabs;
