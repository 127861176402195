import React, { useState } from 'react';

import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { getToken } from './Utils/Common';
import axios from 'axios';
import { Chart as ChartJS } from 'chart.js/auto'
import { Line } from 'react-chartjs-2'


function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function createColumns(data) {

    const dataCopy = JSON.parse(JSON.stringify(data));
    const columns = {};

    for (const [name, values] of Object.entries(dataCopy)) {
        columns[name] = Object.values(values);
    }

    return columns;
}


function ChartImage() {
    const [securityName, setSecurityName] = useState('');
    const [sector, setSector] = useState('');
    const [dataEndpoint, setDataEndpoint] = useState();
    const [chartData, setChartData] = useState([]);
    const [filter, setFilter] = useState('all')
    const [warningMessage, setWarningMessage] = useState('');

    const fetchData = () => {
        const token = getToken();
        if (!token) {
            return;
        }
        if (securityName && sector) {
            setDataEndpoint(`/chartdata/${sector}/${securityName}.csv`);
            let url = new URL(process.env.REACT_APP_API_URL);
            url.pathname += dataEndpoint;
            console.log(url.pathname)
            axios
                .get(url, {
                    auth: {
                        username: token,
                        password: 'unused',
                    },
                })
                .then((res) => {
                    const columns = createColumns(res.data);
                    let labels = columns['index'];
                    let pxLast = columns['PX_LAST'];
                    let daily_10_ranking = columns['daily_10_ranking'];
                    let daily_20_ranking = columns['daily_20_ranking'];
                    let daily_30_ranking = columns['daily_30_ranking'];
                    let daily_40_ranking = columns['daily_40_ranking'];
                    let weekly_27_ranking = columns['weekly_27_ranking'];

                    const currentDate = new Date();
                    const nYearAgo = new Date(currentDate);

                    if (filter === '1year') {
                        nYearAgo.setFullYear(nYearAgo.getFullYear() - 1);
                    } else if (filter === '2years') {
                        nYearAgo.setFullYear(nYearAgo.getFullYear() - 2);
                    } else if (filter === '6month') {
                        nYearAgo.setMonth(nYearAgo.getMonth() - 6);
                    } else {
                        nYearAgo.setFullYear(nYearAgo.getFullYear() - 10);
                    }

                    const filteredLabels = labels.filter((label) => {
                        const labelDate = new Date(label);
                        return labelDate >= nYearAgo;
                    });
                    console.log(filteredLabels)
                    const filteredPxLast = pxLast.slice(-filteredLabels.length);
                    const filteredDaily10 = daily_10_ranking.slice(-filteredLabels.length);
                    const filteredDaily20 = daily_20_ranking.slice(-filteredLabels.length);
                    const filteredDaily30 = daily_30_ranking.slice(-filteredLabels.length);
                    const filteredDaily40 = daily_40_ranking.slice(-filteredLabels.length);
                    const filteredWeekly27 = weekly_27_ranking.slice(-filteredLabels.length);

                    setChartData({
                        labels: filteredLabels,
                        datasets: [
                            {
                                label: 'PX_LAST',
                                data: filteredPxLast,
                                borderColor: 'rgba(255, 255, 255, 1)',
                                yAxisID: 'y',
                                hidden:false,
                            },
                            {
                                label: 'DAILY_10',
                                data: filteredDaily10,
                                borderColor: 'rgba(0, 255, 255, 1)',
                                yAxisID: 'y1',
                                hidden:true,
                            },
                            {
                                label: 'DAILY_20',
                                data: filteredDaily20,
                                borderColor: 'rgba(138, 43, 226, 1)',
                                yAxisID: 'y1',
                                hidden:true,
                            },
                            {
                                label: 'DAILY_30',
                                data: filteredDaily30,
                                borderColor: 'rgba(255, 0, 255, 1)',
                                yAxisID: 'y1',
                                hidden:true,
                            },
                            {
                                label: 'DAILY_40',
                                data: filteredDaily40,
                                borderColor: 'rgba(118, 25, 75, 1)',
                                yAxisID: 'y1',
                                hidden:true,
                            },
                            {
                                label: 'WEEKLY_27',
                                data: filteredWeekly27,
                                borderColor: 'rgba(205, 75, 75, 1)',
                                yAxisID: 'y1',
                                hidden:false,
                            },
                        ],
                    });
                    setWarningMessage('');
                })
                .catch((error) => {
                    console.error(error);
                    if (error.response && error.response.status === 404) {
                    setWarningMessage("Failed to fetch data. Please check your sector and security values.");
                    setChartData(null);}
                })
        }
    };

    const options = {
        maintainAspectRatio: false,
        scales: {
            x: {
                title: {
                    display: true,
                    text: 'DATE',
                },
            },
            y: {
                beginAtZero: false,
                type: 'linear',
                display: true,
                position: 'left',
                title: { display: true, text: 'PRICE' }
            },
            y1: {
                beginAtZero: false,
                type: 'linear',
                display: true,
                position: 'right',
                title: { display: true, text: 'RANKING' }
            }
        },
    };

    return (
        <div>
            <ChartData
                securityName={securityName}
                setSecurityName={setSecurityName}
                sector={sector}
                setSector={setSector}
                fetchData={fetchData}
            />
            <div>
                <button onClick={() => setFilter('all')}>All</button>
                <button onClick={() => setFilter('6month')}>6 Month</button>
                <button onClick={() => setFilter('1year')}>1 Year</button>
                <button onClick={() => setFilter('2years')}>2 Years</button>
            </div>
            {warningMessage && (
                <div style={{ color: 'red', marginTop: '10px' }}>
                    {warningMessage}
                </div>
            )}
            <div>
                {chartData && chartData.labels && (
                    <Line
                        data={{
                            labels: chartData.labels,
                            datasets: Object.entries(chartData.datasets).map(([label, dataset]) => ({
                                label: dataset.label,
                                data: dataset.data,
                                borderColor: dataset.borderColor,
                                yAxisID: dataset.yAxisID,
                                hidden: dataset.hidden
                            })),
                        }}
                        style={{ width: 750, height: 500 }}
                        options={options}
                    />
                )
                }
            </div>
        </div>
    );
}

const ChartData = ({ securityName, setSecurityName, sector, setSector, fetchData }) => {
    const handleSecurityChange = (e) => {
        setSecurityName(e.target.value.toUpperCase());
    };

    const handleSectorChange = (e) => {
        setSector(e.target.value.toLowerCase());
    };

    const handleSubmit = () => {
        fetchData();
    };

    return (
        <div>
            <h1>Security Price and Ranking Chart</h1>
            <input
                type="text"
                value={sector}
                onChange={handleSectorChange}
                placeholder="Enter sector"
            />
            <input
                type="text"
                value={securityName}
                onChange={handleSecurityChange}
                placeholder="Enter security name"
            />
            <button onClick={handleSubmit}>Submit</button>
            <p>You typed: {sector}/{securityName}</p>
        </div>
    );
};

export default ChartImage;