import React from 'react';
import PortfolioEditorTable from './PortfolioEditorTable.js';
import AppBar from './AppBar.js';

function Portfolio(props) {
  return (
    <div>
      <AppBar {...props}/>
      <PortfolioEditorTable {...props}/>
    </div>
  );
}

export default Portfolio;
