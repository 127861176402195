import React, {useState} from 'react';
import axios from 'axios';
import {setUserSession} from './Utils/Common';
import Snackbar from '@mui/material/Snackbar';
import Grid from '@mui/material/Grid';
import LoginForm from './LoginForm.js';
import { useNavigate } from 'react-router-dom';

function Login(props) {
  const [alert, setAlert] = useState('');
  const history = useNavigate();
  const handleLogin = (username, password) => {
    axios.get(`${process.env.REACT_APP_API_URL}/login`, {
      auth: {
        username: username.username,
        password: password.password,
      },

    }).then((response) => {
      setUserSession(response.data.token, username.username);
      history('/indicators');
    }).catch((error) => {
      if (error.response) {
        if (error.response.status === 401) {
          setAlert('Invalid username or password!');
        }
      } else {
        setAlert('Ooops... something went wrong. Try again later!');
      }
    });
  };

  return (
    <div>
      <Snackbar
        open={alert !== ''}
        message={alert}
        autoHideDuration={6000}
      />
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        style={{minHeight: '100vh'}}
      >
        <Grid item xs={3}>
          <LoginForm onLogin={handleLogin}/>
        </Grid>
      </Grid>
    </div>
  );
}

export default Login;
