import React, { useState } from 'react';
import { Button, TextField } from '@mui/material';

export default function Login(props) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  function validateForm() {
    return username.length > 0 && password.length > 0;
  }

  function handleSubmit(event) {
    props.onLogin({username}, {password});
    event.preventDefault();
  }

  return (
    <div className="Login" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <form id="login" onSubmit={handleSubmit}>
        <TextField
          label="Username"
          type="text"
          onChange={(e) => {
            setUsername(e.target.value);
          }}
          autoComplete="username"
        />
        <TextField
          label="Password"
          type="password"
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          autoComplete="current-password"
        />
        <Button
          disabled={!validateForm()}
          type="submit"
          value="Sign In!"
        >Login</Button>
      </form>
    </div>
  );
}
