import React, {useState, useEffect} from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import axios from 'axios';
import Login from './Login';
import Indicators from './Indicators';
import Ranking from './Ranking';
import Micro from './Micro';
import Chart from './Chart';
import Score from './Score';
import Portfolio from './Portfolio.js';
import {getToken, removeUserSession, setUserSession} from './Utils/Common';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

function App(props) {
 
  const [authLoading, setAuthLoading] = useState(true);

  const theme = createTheme({
    palette: {
      mode: 'dark',
    },
  });

  useEffect(() => {
    const token = getToken();
    if (!token) {
      return;
    }

    console.log(process.env.REACT_APP_API_URL);

    axios.get(`${process.env.REACT_APP_API_URL}/verify_token?token=${token}`)
      .then((response) => {
        setUserSession(response.data.token, response.data.user);
        setAuthLoading(false);
        // test
      }).catch((error) => {
        removeUserSession();
        setAuthLoading(false);
      });
  }, []);

  if (authLoading && getToken()) {
    return <div className="content">Checking Authentication...</div>;
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline/>
      <Router>
        <div>
          <div className="content">
            <Routes>
              <Route path="/login" element={<Login/>} />
              <Route path="/indicators" element={<Indicators {...props}/>} />
              <Route path="/micro" element={<Micro {...props}/> } />
              <Route path="/score" element={<Score/>}/>
              <Route path="/ranking" element={<Ranking/>}/>
              <Route path="/portfolio" element={<Portfolio/>}/>
              <Route path="/chart" element={<Chart/>}/>
            </Routes>
          </div>
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
