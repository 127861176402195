import React from 'react';
import RankingTabs from './RankingTabs.js';
import AppBar from './AppBar.js';

function Ranking(props) {
  return (
    <div>
      <AppBar {...props}/>
      <RankingTabs {...props}/>
    </div>
  );
}

export default Ranking;
